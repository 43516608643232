<template>
  <div class="template-intro-text">
    <p class="intro-text" v-html="candidate.introText"> 
    </p>
    
  </div>
</template>

<script>
export default {
  computed: {
    candidate() {
      return this.$store.getters["candidates/candidate"];
    }
  },
  mounted() {

    let lineBreaks = document.querySelectorAll('.template-intro-text .intro-text p > br');

    for (let i = 0; i < lineBreaks.length; i++) {

      let breakParent = lineBreaks[i].parentNode;

      breakParent.classList.add('d-none');
      
    }
    
  }
};
</script>

<style lang="scss" scoped></style>
